import React, { useState } from 'react';

import {
  Box,
  Text,
  Table,
  Tbody,
  Tr,
  Td,
  Heading,
  Flex,
} from '@chakra-ui/react';
import {
  GoogleMap, Marker, useJsApiLoader,
} from '@react-google-maps/api';
import * as styles from 'styles/shared.module.scss';
import { graphql, useStaticQuery } from 'gatsby';

export const query = graphql`
  {
    allStrapiContactPage {
      nodes {
        email
        description
        titleMap,
        titleContacts,
        titlePhone,
        titleEmail,
        phone
      }
    }
  }
`;
function ContactPage() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyDecTwVi-2cB5JuH8e4r1fsiXZLJ28aiAM',
  });

  const data = useStaticQuery(query);
  const contactData = data.allStrapiContactPage.nodes[0];

  const [center, setCenter] = useState({ lat: 47.789552, lng: 25.791437 });

  const containerStyle = {
    height: '400px',
    borderRadius: 10,
  };

  return (
    <>
      <Box className={styles.whitePresentationContainer}>
        <Box className={`${styles.descriptionWithImageWrapper} ${styles.showLarge}`}>
          <Heading as="h1" style={{ marginBottom: '3rem' }}>{contactData.titleContacts}</Heading>
          <Table variant="simple">
            <Tbody>
              <Tr>
                <Td style={{ backgroundColor: '#fbfbfb' }}>
                  <Text align="center">{contactData.titlePhone}</Text>
                </Td>
                <Td style={{ backgroundColor: '#fbfbfb' }}>
                  <Text align="center">{contactData.titleEmail}</Text>
                </Td>
              </Tr>

              <Tr>
                <Td>
                  <Text align="center">{contactData.phone}</Text>
                </Td>
                <Td>
                  <Text align="center">{contactData.email}</Text>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>

        <Box className={`${styles.descriptionWithImageWrapper} ${styles.showSmall}`}>
          <Heading as="h1" style={{ marginBottom: '3rem' }}>{contactData.titleContacts}</Heading>
          <Table variant="simple">
            <Tbody>
              <Tr>
                <Td style={{ backgroundColor: '#fbfbfb' }}>
                  <Text align="center">{contactData.titlePhone}</Text>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <Text align="center">{contactData.phone}</Text>
                </Td>
              </Tr>
              <Tr>
                <Td style={{ backgroundColor: '#fbfbfb' }}>
                  <Text align="center">{contactData.titleEmail}</Text>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <Text align="center">{contactData.email}</Text>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
      </Box>
      <Box className={styles.grayPresentationContainer}>
        <Box className={styles.descriptionWithImageWrapper}>
          <Flex justify="center" align="center" direction="column">
            <Box>
              <Heading as="h1" style={{ marginBottom: '3rem' }}>{contactData.titleMap}</Heading>
              <Text as="p" size="xl" align="justify" style={{ marginBottom: 30 }}>
                {contactData.description}
              </Text>
            </Box>
            {isLoaded
              && (
              <GoogleMap
                mapContainerClassName={styles.mapWrapper}
                mapContainerStyle={containerStyle}
                center={center}
                zoom={13}
              >
                <Marker position={center} />
              </GoogleMap>
              )}
          </Flex>
        </Box>
      </Box>
    </>
  );
}

export default ContactPage;
